import win from 'win';

let helper = {

    getCookie: function(cname) {
        let name = cname + "=";
        let decodedCookie = win.decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    loadStyle: function (url, callback) {
        let styleTag = win.document.createElement('link');
        styleTag.setAttribute('type', 'text/css');
        styleTag.setAttribute('rel', 'stylesheet');
        styleTag.setAttribute('href', url);
        if (typeof callback !== 'undefined') {
            if (styleTag.readyState) {
                /* For old versions of IE */
                styleTag.onreadystatechange = function () {
                    if (this.readyState === 'complete' || this.readyState === 'loaded') {
                        callback();
                    }
                };
            } else {
                styleTag.onload = callback;
            }
        }
        (win.document.getElementsByTagName('head')[0] || win.document.documentElement).appendChild(styleTag);
    },

    //There is the same copy of this truncate util in ResponsiveCheckout (https://github.com/Webjet/paymentweb), and on WCS.
    //When updating this util, make sure to also update the other two places (and their unit tests) to have consistent behaviour.
    truncate: function (value, digits) {

        //This is a reliable approach on truncating a number without using * and / and math functions.
        //Other approaches have resulted to undesirable results when passing certain input values.
        
        value = value || 0.00;
        digits = typeof digits === 'undefined' || digits === null || digits < 1 
            ? 2 : digits;

        let re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
            m = value.toString().match(re);
       
        return m ? parseFloat(m[1]) : value;
    }

};

export default helper;