import constant from 'services/constants';
import banner from 'components/Banner';
import modalService from 'services/modalService';
import helperService from 'services/helperService';
import win from 'win';

function App() {

    let self = this;

    function isAuthFailed() {
        return win.location.hash.indexOf(constant.hashUrlAuthFailed) > -1;
    };

    function initFailedAuthModal() {
        //Show the message dialog on initial landing page.
        let options = {
            header: "NAB Rewards error",
            message: "An error occured while retrieving your NAB Rewards details.\n\nWe recommend logging into the NAB Rewards site and trying again.",
            btnText: "Continue without NAB Rewards"
        };
         
        modalService.show(options);
    };

    function initBanner(config){
        banner.init(config);
    };

    self.init = function (config) {

        if (isAuthFailed()) {
            //failed authentication. Do not load or call further external api
            initFailedAuthModal();
        }
        else if (helperService.getCookie('nr_a')) {
            
            initBanner(config);
        }
    };

};

export default App;