import './styles/main.less';
import './images/nab-logo.png';
import './images/nab-rewards.png';
import bannerHtml from './_banner.html';
import constant from '../../services/constants';
import { fetch } from '../../services/apiClientService';
import helperService from '../../services/helperService';
import win from 'win';
import jq from 'jquery';


function Banner(){ 

    let self = this,
        config = null,
        bannerContainer = null,
        msgHub = jq(win.document),
        bannerData = null;

    function insertBannerPlaceHolder() {
        let scriptTag = win.document.getElementById('a87654321');
        let newElm = win.document.createElement('div');
        newElm.id = 'aa2c2dfb';
        newElm.className = 'bb2c2dfa';

        if(newElm.dataset)
            newElm.dataset.provider = 'nabrewardsexternal';
        else
            newElm.setAttribute('data-provider', 'nabrewardsexternal');

        scriptTag.parentNode.insertBefore(newElm, scriptTag);
        return newElm;
    };

    function createBannerHtml() {
        let banner = bannerHtml.replace('{nab-image-logo-url}', config.nabImageLogoUrl);
        return banner.replace('{nab-image-nabrewards-url}', config.nabImageNabRewardsUrl);
    };

    function initBannerHtml() {
        let placeHolder = insertBannerPlaceHolder();
        bannerContainer = jq(placeHolder);
        let bannerHtml = createBannerHtml();

        if (placeHolder && bannerHtml)
            placeHolder.innerHTML = bannerHtml;

        placeHolder.style.display = 'inline';

        win.document.body.classList.add('nabrewards-active');
    };

    function updateBannerWithData(data) {

        bannerData = data;
        if (!data)
            return;

        let memberDetails = bannerContainer.find('#member-details:first'),
            name = memberDetails.find('#name:first'),
            points = memberDetails.find('#points-balance:first'),
            value = memberDetails.find('#value:first');

        let dollarVal = data.pointsPerDollar > 0
                    ? data.pointsBalance / data.pointsPerDollar
                    : 0;

        //Truncate to whole number when not fractional, otherwise round down to two decimal places.
        dollarVal = (dollarVal % 1) === 0
            ? Math.floor(dollarVal)
            : helperService.truncate(dollarVal, 2);

        name.text(data.name);
        points.text(data.pointsBalance);
        value.text('$' + dollarVal);
    };

    function initBannerStyle() {
        helperService.loadStyle(config.bannerCss);
    };

    function initBannerMessageHub(data) {

        let pointDollar = bannerContainer.find('#point-dollar:first'),
            pointsBtn = pointDollar.find('#points:first'),
            dollarBtn = pointDollar.find('#dollars:first'),
            activeBtnCss = 'active',
            pointsMode = false;

        function setPointsBtnActive() {
            pointsMode = true;
            dollarBtn.removeClass(activeBtnCss);
            pointsBtn.addClass(activeBtnCss);
        };

        function setDollarBtnActive() {
            pointsMode = false;
            pointsBtn.removeClass(activeBtnCss);
            dollarBtn.addClass(activeBtnCss);
        }

        setDollarBtnActive();

        msgHub.on(constant.eventTopic.modeSectionShow, function (event, data) {
            pointDollar.show();

            if (data && data.isPointsMode)
                setPointsBtnActive();
            else
                setDollarBtnActive();

        });

        msgHub.on(constant.eventTopic.modeSectionHide, function () {
            pointDollar.hide();
        });

        msgHub.on(constant.eventTopic.pointsBtnClicked, function () {
            setPointsBtnActive();
        });

        msgHub.on(constant.eventTopic.dollarBtnClicked, function () {
            setDollarBtnActive();
        });

        pointsBtn.click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            setPointsBtnActive();
            msgHub.trigger(constant.eventTopic.pointsBtnClicked, {
                pointsPerDollar: bannerData.pointsPerDollar
            });
        });

        dollarBtn.click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            setDollarBtnActive();
            msgHub.trigger(constant.eventTopic.dollarBtnClicked, {
                pointsPerDollar: bannerData.pointsPerDollar
            });
        });

        //Trigger the init only when all scripts are loaded
        jq(function () {
            msgHub.trigger(constant.eventTopic.init, {
                nabUser: data,
            });
        })
    };

    self.init = function(conf){

        config = conf;

        initBannerStyle();

        fetch({src: config.dataUrl})
            .then(function(data){
                initBannerHtml();
                updateBannerWithData(data);
                initBannerMessageHub(data);
            });
    };
};

export default new Banner();