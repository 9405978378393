import win from 'win';
import jq from 'jquery';

let modal = {

    show: function(options){

        let removeHashInUrl = function () {
            //After redirect, only Nab rewards api adds the hash keys, so it's safe to remove.
            win.history.pushState("", win.document.title, win.location.pathname + win.location.search);
        };

        jq(function () {
            //show the dialog in desktop
            if (jq.fn.modal
                && win
                && win.Webjet
                && win.Webjet.Common
                && win.Webjet.Common.showModalPopup) {   //bootstrap modal
                win.Webjet.Common.showModalPopup({
                    config: {
                        Title: options.header,
                        Message: options.message,
                        ActionText: options.btnText
                    },
                    okCSSClass: "btn-action",
                    okHandler: removeHashInUrl,
                    onHideHandler: removeHashInUrl
                });
            }
        });

        //show the dialog in mobile, jquery mobile
        if (jq.fn.popup
            && win
            && win.Webjet
            && win.Webjet.Common
            && win.Webjet.Common.modal) {
            win.Webjet.Common.modal({
                headerText: options.header,
                message: options.message,
                buttonText: options.btnText,
                buttonHandler: null,
                buttonCss: 'btn-action',
                afterCloseHandler: removeHashInUrl
            });
        };
    }
};

export default modal;