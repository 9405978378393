const constants = {
    eventTopic: {
        init: 'NabRewards.Banner.Init',
        pointsBtnClicked: 'NabRewards.Banner.PointsBtnClicked',
        dollarBtnClicked: 'NabRewards.Banner.DolarBtnClicked',
        modeSectionShow: 'NabRewards.Banner.ModeSectionShow',
        modeSectionHide: 'NabRewards.Banner.ModeSectionHide'
    },
    frameBusTopic: {
        dataLoaded: 'NabRewards.Banner.Data.Received'
    },
    hashUrlAuthFailed: 'nabrewardserror',
    headerName: {
        csrfToken: 'X-CSRF-Token'
    },
    queryStringName: {
        csrftoken: 'csrftoken'
    },
    cookieName:{
        csrftoken: 'nr-CSRF-Token'
    }
};

export default constants;