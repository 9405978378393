import framebus from 'framebus';
import win from 'win';
import constant from './constants';
import Promise from 'es6-promise';
import fetchPonyFill from 'fetch-ponyfill';
import helperService from 'services/helperService';


const es6Fetch = fetchPonyFill( {Promise: Promise} ).fetch;


function fetchByIFrame(options){

    function insertIFrameForApiData(src) {
        let newElm = win.document.createElement('iframe');
        newElm.style.display = "none";
        newElm.src = src;

        let scriptTag = win.document.getElementById('a87654321');
        scriptTag.parentNode.insertBefore(newElm, scriptTag);
    };

    return new Promise(function(resolve, reject){
    
        function callback(data){
            resolve(data);
        };

        try {
            //Add query for format=iFrame and for CSRF
            let url = options.src + '?f=if&' + constant.queryStringName.csrftoken + '=' + getAntiForgeryToken();

            framebus.on(constant.frameBusTopic.dataLoaded, callback);
            insertIFrameForApiData(url);  //format=iFrame
        }
        catch(err) {
            reject(err.message);
        }
    });
};

function fetchByCors(options){

    function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    };

    function parseJSON(response) {
        return response.json()
    };

    var csrfToken = getAntiForgeryToken();

    return es6Fetch(options.src,
        {
            credentials: 'include',
            headers: {
                [constant.headerName.csrfToken]: csrfToken
            }
        })
      .then(checkStatus)
      .then(parseJSON); 
};

function getAntiForgeryToken(){
    return helperService.getCookie(constant.cookieName.csrftoken);
}


export function fetch(options) {
    //Detect browser support for CORS
    if ('withCredentials' in new win.XMLHttpRequest())
        return fetchByCors(options);
    else
        return fetchByIFrame(options);
};

